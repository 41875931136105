import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Table, Input, Spin, Layout, Card, Tag } from 'antd';
import { LoadingOutlined, SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import QRCode from 'qrcode.react';
import styles from './ExpiryList.module.css';

const { Search } = Input;
const { Content } = Layout;

const apiUrl = 'https://script.google.com/macros/s/AKfycbzPn-2By21Ckx8UBglUpZGTwbkvF04j4ojlZ74nEW96lNKOZ5zvHLW6PhVt02tGgrmJFg/exec?action=getUsers';

const Home = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false); // เพิ่มสถานะการโหลดสำหรับการค้นหา

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        const json = await response.json();

        const calculatedData = json.map((item) => {
          if (!item['แพคเกจเริ่มต้น']) {
            return null;
          }

          const email = item['แพคเกจเริ่มต้น'].trim().toLowerCase();
          const daysLeft = item.email; // ใช้ค่าตัวเลขในฟิลด์ 'email' เป็นจำนวนวันที่เหลือ
          const isExpired = daysLeft <= 0;

          return {
            id: item.id,
            email: email,
            daysLeft: `${daysLeft} วัน`,
            isExpired: isExpired,
          };
        }).filter(item => item !== null);

        setData(calculatedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value) => {
    setSearchLoading(true); // เริ่มการโหลดสำหรับการค้นหา
    setTimeout(() => {
      if (value) {
        const term = value.toLowerCase().trim();
        const result = data.filter((item) => item.email === term);
        setFilteredData(result);
      } else {
        setFilteredData([]);
      }
      setSearchLoading(false); // สิ้นสุดการโหลดสำหรับการค้นหา
    }, 1000); // เพิ่มเวลาหน่วงเพื่อแสดงสถานะการโหลด
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '50%',
    },
    {
      title: 'Days Left',
      dataIndex: 'daysLeft',
      key: 'daysLeft',
      width: '25%',
    },
    {
      title: 'Status',
      key: 'status',
      width: '25%',
      render: (text, record) => (
        record.isExpired ? (
          <div>
            <Tag icon={<CloseCircleOutlined />} color="red">
              หมดอายุ
            </Tag>
            <div>
              <QRCode value="https://line.me/R/ti/p/@premium4u" size={64} />
              <div>Line ID: @premium4u</div>
            </div>
          </div>
        ) : (
          <Tag icon={<CheckCircleOutlined />} color="green">
            ยังไม่หมดอายุ
          </Tag>
        )
      ),
    }
  ];

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <Card className={styles.card}>
          <Search
            placeholder="พิมพ์ Email ของท่าน..."
            onSearch={handleSearch}
            enterButton
            prefix={<SearchOutlined />}
            className={styles.search}
          />
          {loading ? (
            <div className={styles.spinner}>
              <Spin indicator={antIcon} />
            </div>
          ) : searchLoading ? (
            <div className={styles.spinner}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <Table
              dataSource={filteredData}
              columns={columns}
              rowKey="id"
              className={styles.table}
              pagination={false}
            />
          )}
        </Card>
      </Content>
    </Layout>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<Home />);
